/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rechargeable-hearing-aids",
    "aria-label": "rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rechargeable Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Consumers have been asking for the convenience of ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "rechargeable hearing aids"), ". Conventional ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " operate on disposable ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries/",
    className: "c-md-a"
  }, "zinc air batteries"), " that last about a week. People find it hard to remember to carry around an extra pack of batteries. The tiny batteries can be challenging to remove and replace. Zinc air batteries also require you to wait for a couple of minutes once you peel the sticker off for best performance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, engineers have found it challenging to design rechargeable batteries that can keep up with the demands of today’s hearing aid technology. Streaming audio via ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth"), " requires a consistent voltage and consumes a lot of battery power."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton introduces the Stellar hearing aids with a lithium-ion rechargeable battery. You can simply slip these into the induction charger. There are no charging contacts. After only four hours in the charger, these hearing aids run for 24 hours. For people with poor vision or dexterity problems, these hearing aids can be a life changer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "popular-ric-style-for-discreet-wear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#popular-ric-style-for-discreet-wear",
    "aria-label": "popular ric style for discreet wear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Popular RIC Style for Discreet Wear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton Stellar comes in a Receiver-in-the-Ear body that slips discreetly behind your ear. A thin wire conveys the sound into your ear. These devices are available in your choice of colors to blend into your hair or your skin tone. Their IP68 rating means that these hearing aids have been designed with superior protection against moisture and dust. While you don’t want to take these hearing aids for a swim in the pool, you can enjoy the peace of mind that a simple rainstorm won’t destroy your devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "RIC hearing aids provide your hearing care professional with the flexibility to fit a wide range of hearing losses from mild to severe. If your hearing changes, your hearing care professional can simply hook the hearing aid up to a computer and change the internal program. If you need more power, the hearing care professional can change the receiver strength. You may opt to use a remote control if you do not like fiddling with buttons."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s an integrated telecoil that you may use to help you hear better on the telephone. Many venues such as movie theaters have loop systems for the hearing impaired that will send sound into your hearing aids via the telecoil feature."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Stellar now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "advanced-technology-for-your-lifestyle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#advanced-technology-for-your-lifestyle",
    "aria-label": "advanced technology for your lifestyle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Advanced Technology for Your Lifestyle"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Bluetooth streaming capabilities mean that you can listen to your smart phone or other audio devices directly through your hearing aids. Your smart phone can even act as a remote control through an app. With a few taps and swipes, you can control the volume, program, and tone of the hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton offers accessory devices to transmit sound into your hearing aids from a television and from a remote microphone. Direct sound transmission overcomes problems of distance, noise, and reverberation. With these accessory options, you will find it easier to overcome difficult listening situations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have difficulty with tinnitus (ringing) in your ears, your hearing care professional can turn on the tinnitus program option. This option will mask your tinnitus, making it less bothersome. Over time with professionally-assisted sound therapy, you may find that your brain adjusts itself so that your tinnitus becomes less noticeable."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You will also enjoy the flexibility of multiple programs stored on your hearing aids for different listening situations. However, you can also let the hearing aids adjust themselves automatically. The hearing aids have a sophisticated sound classifier to identify the kind of listening environment that you currently occupy. The Music Enhancer feature helps music sound more natural. Many people find that music sounds artificial through hearing aids programmed for speech understanding."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Voice Ranger technology works to make it easier for you to hear your conversation partner in noisy environments. You should not have to strain so hard to hear your friend’s latest news. The Reverb Reducer tones down the echoes that might otherwise bother you in environments with hard floors and furniture."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-personalized-support-from-our-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-personalized-support-from-our-experts",
    "aria-label": "get personalized support from our experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Personalized Support From Our Experts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you sign up for a risk-free trial at hear.com, one of our experts will call you and discuss your needs. Ask all your questions about hearing loss and hearing aids. Our expert will then schedule you to meet with one of our Partner Providers for a hearing evaluation and hearing aid consultation. The provider will fit you with the hearing aid model that is right for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You will have 45 days to try the hearing aids risk-free. If you are not satisfied, just return them for a refund. Throughout the entire journey, our experts will remain available by phone for support. We can even support you in finding financing for your purchase."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Stellar now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
